<template>
  <v-card
    height="100%"
  >
    <v-card-title
      v-if="actualTab"
      class="font-weight-regular justify-center text-center"
    >
      {{ actualTab.text }} <br>{{ $t('mixed|categories') }}
    </v-card-title>
    <v-container
      fluid
    >
      <v-row class="text-center">
        <v-col cols="12">
          <CaseModelsCategoriesList
            :actual-tab="actualTab"
          />
          <div v-if="actualTab && !actualTab.isLavviraTab">
            <v-btn
              id="template-category-btn"
              rounded
              small
              color="primary"
              style="font-size: 11px;"
              class="button-add-position mb-n4"
              @click="addCategoriesAction"
            >
              <v-icon
                class="ml-n2 mr-1"
              >
                mdi-plus
              </v-icon>
              {{ $t('actions|add_categories') }}
            </v-btn>
            <v-btn
              small
              rounded
              color="primary"
              style="font-size: 11px;"
              class="button-edit-position"
              @click="editCategoriesAction"
            >
              <v-icon
                class="ml-n2 mr-2"
                small
              >
                mdi-pencil
              </v-icon>
              {{ $t('actions|edit_categories') }}
            </v-btn>
          </div>

          <div v-if="actualTab && actualTab.isLavviraTab && account.accountType === 'god'">
            <v-btn
              id="template-category-btn"
              rounded
              small
              color="primary"
              style="font-size: 11px;"
              class="button-add-position mb-n4"
              @click="addCategoriesAction"
            >
              {{ $t('actions|add_categories') }}
              <v-icon
                class="ml-2"
              >
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              small
              rounded
              color="primary"
              style="font-size: 11px;"
              class="button-edit-position"
              @click="editCategoriesAction"
            >
              {{ $t('actions|edit_categories') }}
              <v-icon
                class="ml-2"
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <AddCaseModelCategories
      ref="AddCaseModelCategories"
    />
    <EditCaseModelsCategories
      ref="EditCaseModelsCategories"
    />
  </v-card>
</template>

<script>
import AddCaseModelCategories from './AddCaseModelCategories.vue'
import CaseModelsCategoriesList from './CaseModelsCategoriesList.vue'
import EditCaseModelsCategories from './EditCaseModelsCategories.vue'
import { mapState } from 'vuex'

export default {
  components: { AddCaseModelCategories, CaseModelsCategoriesList, EditCaseModelsCategories },
  props: {
    actualTab: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    })
  },
  methods: {
    addCategoriesAction () {
      this.$refs.AddCaseModelCategories.onAddCaseModelsCategoriesEvent(this.actualTab)
    },
    editCategoriesAction () {
      this.$refs.EditCaseModelsCategories.onEditCaseModelsCategoriesEvent(this.actualTab)
    }
  }
}
</script>

<style scoped>
.button-edit-position {
  position: absolute;
  width: 80%;
  bottom: 18px;
  left: 38px;
}
.button-add-position {
  position: absolute;
  width: 80%;
  bottom: 75px;
  left: 38px;
}
</style>
